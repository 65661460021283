import React, { Fragment, useContext } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  Splash,
  SeeMoreLink,
  PlusIcon,
  ArrowDownIcon,
  ArrowDownLink,
  ArrowUpIcon,
  ArrowUpLink,
  Text2Col,
  Grid2Col,
} from '../components/Elements';
import * as modalTypes from '../types/modalTypes';
import ModalContext from '../store/modalContext';
import SEO from '../components/SEO';

const componentsQuery = graphql`
  {
    datoCmsHomepage {
      titreDeLaSectionBienvenue
      texteDeLaSectionBienvenue
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default function ComponentsPage() {
  const data = useStaticQuery(componentsQuery);
  const { openModal } = useContext(ModalContext);
  const {
    titreDeLaSectionBienvenue,
    texteDeLaSectionBienvenue,
    seoMetaTags,
  } = data.datoCmsHomepage;

  return (
    <Fragment>
      
      <PageWrapper>
        <PageInner>
          <PageTitle>Components</PageTitle>
          <div
            dangerouslySetInnerHTML={{ __html: texteDeLaSectionBienvenue }}
          />
          <br/><br/>
          
          <Text2Col><p>
          Indeed, the element the grid is applied to is being fragmented into the multi-column container, but there’s still only one top and one bottom sides to it. So the bottom-border only applies to the bottom side of the grid element for example.
Obviously that doesn’t look so good. Fortunately, CSS provides a way to solve this too: box-decoration-break. This CSS property can be used to define how things like background, borders, margins and paddings work in fragmentation cases (or in cases like an inline element wrapping on several lines of text).
Using box-decoration-break: clone; we get the effect we want:
Image for post
borders and paddings are now cloned in all fragments
That’s it! You can play with the complete example here on codepen, it also includes some fun little CSS counters which I used to number the labels via CSS only.
Happy coding, bye for now.  
          </p></Text2Col>
         <br/><br/>
      
          <Grid2Col>
            <div>
                 
          <button onClick={() => openModal(modalTypes.BASIC)}>
            Open Modal
          </button>
          <button onClick={() => openModal(modalTypes.SPECIAL)}>
            Open Modal speciale
          </button>
          <Link to="/projet">
            <button css={{ marginLeft: '.5em' }}>Page projets</button>
          </Link> 
            </div>
            <div>
                <AnchorLink to="/#top" title="Retour en haut" /> <br/><br/>
            <SeeMoreLink to="/projets" title="Les voir tous">
              Les voir tous <PlusIcon title="Voir tous les projets" />
            </SeeMoreLink><br/><br/>
            <ArrowDownLink to="/projets" title="Section suivante">
              <ArrowDownIcon title="Section suivante" />
              Flèche bas
            </ArrowDownLink><br/><br/>
            <ArrowUpLink to="/projets" title="Section précédente">
              Flèche haut
              <ArrowUpIcon title="Section précédente" />
            </ArrowUpLink><br/><br/>
            </div>

          </Grid2Col>
        </PageInner>
      </PageWrapper>
    </Fragment>
  );
}
